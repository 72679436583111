@mixin hide_for_mobile {
  @media screen and (max-width: 615px) {
    display: none;
  }
}
@mixin hide_for_desktop {
  @media screen and (min-width: 615px) {
    display: none;
  }
}

.groupWidget_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .groupWidget_top {
    padding: 0rem 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 615px) {
      padding: 0rem 0rem;
      margin-bottom: 0rem;
      width: 100%;
    }
    .groupWidget_top_heading {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 42px;
      display: flex;
    
      margin: unset;
      @media screen and (max-width: 900px) {
        font-size: 20px;
      }
      @media screen and (max-width: 600px) {
        font-size: 16px;
        margin-right: 2px;
      }
    }
  }
  .nones {
    @media screen and (max-width: 615px) {
      display: none;
    }
  }
  .overflow_hidden_wrapper,
  .overflow_hidden_wrapper_lect,
  .overflow_hidden_wrapper_talb {
    width: 100%;
    overflow-x: hidden;
    position: relative;

    .prev,
    .next {
      cursor: pointer;
      position: absolute;
      // top: 30px;
      //width: auto;
      padding: 1px;
      color: white;
      font-weight: bold;
      bottom: 45%;
      z-index: 1;

      //padding:3px 12px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      //font-size: 18px;
      transition: 0.7s ease;
      //border-radius: 50%;
      text-decoration: none;
      user-select: none;
    }
    .next {
      right: 7px;
      display: block;
      @media screen and (max-width: 615px) {
        display: none;
      }
    }
    .prev {
      left: 15px;
      display: block;
      @media screen and (max-width: 615px) {
        display: none;
      }
    }

    .prev_none {
      display: none;
    }
    .next_none {
      display: none;
    }
  }

  .overflow_auto_wrapper::-webkit-scrollbar,
  .overflow_auto_wrapper_lect::-webkit-scrollbar,
  .overflow_auto_wrapper_talb::-webkit-scrollbar {
    display: none;
  }
  .overflow_auto_wrapper {
    @media screen and (max-width: 615px) {
      gap: 0.5rem;
      padding: 0.5rem;
    }
  }
  .overflow_auto_wrapper,
  .overflow_auto_wrapper_lect,
  .overflow_auto_wrapper_talb {
    width: 105%;
    display: flex;
    gap: 1em;
    padding-right: 4em;
    padding-left: 1em;
    @media screen and (max-width: 615px) {
      padding-left: 0em;
    }

    flex-direction: row;
    overflow: auto;

    .overflow_auto_after {
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding: 4px;

      @media screen and (max-width: 615px) {
        gap: 16px;
      }

      .groupWidget_lecturer_item {
        @include hide_for_mobile;
        @media screen and (max-width: 615px) {
          display: none;
        }
      }

      .groupWidget_lect_mobile {
        @media screen and (min-width: 615px) {
          display: none;
        }
      }
      .groupWidget_album_item {
        cursor: pointer;
        flex: 0 0 auto;
        width: 220px;
        
        @media screen and (max-width: 615px) {
          width: 180px;
        }
      }
      .groupWidget_album_item {
        width: fit-content !important;
        min-width: 220px !important;
        height: fit-content !important;
        @media screen and (max-width: 615px) {
          min-width: 180px !important;
          max-width: 200px;
          margin-right: 8px;
        }
      }
      .groupWidget_album_item_talb {
        @include hide_for_mobile;
        margin: 0 auto;
      }
    }
  }
  .overflow_auto_wrapper_lect {
    @media screen and (max-width: 615px) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 2%;
      overflow-x: hidden;
    }
    .groupWidget_lect_mobile {
      @include hide_for_desktop;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 92%;
      position: relative;
      background: #222222;
      padding: 7px;
      border-radius: 3rem;
      .lect_mobile_style {
        @include hide_for_desktop;
      }

      .lect_ranks_abs {
        position: absolute;
        color: white;
        font-size: 14px;
        left: -18px;
        top: 2rem;

        //display:flex;
      }
    }
  }

  .overflow_auto_wrapper_talb {
    @media screen and (max-width: 615px) {
      display: grid;
      grid-template-columns: repeat(3, auto);
      width: 100%;
      overflow-x: hidden;
      //flex-direction: column;
      //align-items: flex-start;
      //justify-content: center;
      padding: 2%;

      .col_fill {
        @include hide_for_desktop;
        @media screen and (min-width: 615px) {
          display: none;
        }
        grid-column: 1 / 3;
        width: 91vw;
        //height:fit-content;
        position: relative;
      }
      //float:right;

      .alb_ranks_abs {
        position: absolute;
        color: white;
        font-size: 14px;
        left: 0.9rem;
        top: 2rem;

        //display:flex;
      }
    }
    .other_ranks {
      @include hide_for_desktop;
      background: #222222;
      padding: 7px;
      border-radius: 6px;
      //float: right;
      margin-left: 2rem;
    }

    .nil {
      display: none;
      @include hide_for_desktop;
    }
  }

  .topthree_for_mobile {
    @include hide_for_desktop;
    @media screen and (max-width: 615px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .num_two {
        width: 80px;
        height: 120px;
        overflow: hidden;
        font-family: "Poppins";
        display: flex;
        color: white;
        font-size: 12px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        .toprank_img {
          width: 80px;
          height: 100px;
          border-radius: 6px;
          position: relative;
          .toprank_img_sz {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
          .rank_num_abs {
            color: white;
            font-size: 10px;
            border-radius: 50%;
            padding: 0% 8%;
            background: #3690fb;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -7px;
            left: 31px;
          }
        }
      }
      .num_one {
        width: 100px;
        height: 140px;
        font-family: "Poppins";
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        font-size: 12px;
        color: white;
        .firsttop_img {
          width: 100px;
          height: 120px;
          border-radius: 6px;
          position: relative;
          .firsttop_img_sz {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
          .rank_first_abs {
            color: white;
            font-size: 10px;
            border-radius: 50%;
            padding: 0% 7%;
            background: #ffa134;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -7px;
            left: 41px;
          }
        }
      }
      .num_three {
        width: 80px;
        height: 120px;
        overflow: hidden;
        font-family: "Poppins";
        display: flex;
        color: white;
        font-size: 12px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
        .toprank_img {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          position: relative;
          .toprank_img_sz {
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
          .rank_num_abs {
            color: white;
            font-size: 10px;
            border-radius: 50%;
            padding: 0% 8%;
            background: #8a5a4d;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: -7px;
            left: 31px;
          }
        }
      }
    }
  }

  .groupWidget_carousel_lecturers {
    // .slick-list {
    //   height: 100% !important;
    // }
    // @media screen and (max-width: 513px) {
    //   height: 400px;
    // }
  }
  .groupWidget_close_more {
    height: 14.313rem;
  }
  .groupWidget_albumclose_more {
    height: 276px;
    @media screen and (max-width: 513px) {
      height: 250px;
    }
  }
  .groupWidget_open_more {
    height: 100%;
  }
}
